<template>
  <section id="Property" class="services">
    <div class="container">
      <div class="section-title">
        <h2>SAP Business Objects</h2>
      </div>

      <div class="row" style="padding-top: 30px">
        <div
          class="col-lg-4 col-md-12 col-12 d-flex align-items-stretch"
          v-for="(service, key) in Services"
          :key="key"
        >
          <div class="icon-box">
            <img
              :src="`../webassets/images/${service.image}.jpg`"
              class="serviceimage"
            />
            <h4 class="propertyhead">{{ service.title }}</h4>
            <ul class="list">
              <li>
                <p v-html="$filters.markdown(service.description)"></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      lang: "en",
      Services: [],
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/services/collections/business_objects/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.Services = [];
            return;
          } else if (data.data.length == 0) {
            this.Services = [];
            return;
          }
          this.Services = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
img.serviceimage {
  width: 100%;
  padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Property {
  padding: 50px 30px 10px 30px;
  background: #f9f9f9;
}
.services .icon-box .propertyhead {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}
.propertypar {
  font-size: 14px;
}
.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.list li i {
  color: #0880e8;
  font-size: 15px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
}

.list {
  margin-top: 10px;
  padding: 0px 20px 0px 0px;
}

.list li {
  display: block;
  margin-bottom: 12px;
  font-size: 14px;
  color: #777;
  position: relative;
}
</style>

<template>
  <section class="servicesIcon">
    <div class="container">
      <div class="row">
        <h4>SAP Business Warehouse (BW4HANA)</h4>
        <ul class="list">
          <li>
            <i class="lni lni-checkmark-circle"></i>
            Microsoft Power BI
          </li>
          <li>
            <i class="lni lni-checkmark-circle"></i>
            Data Engineering & Modeling
          </li>
          <li>
            <i class="lni lni-checkmark-circle"></i>
            Wisdom Schema’s BI and Analytics training enables participants to
            design, develop and deploy future ready solutions
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style scoped>
.servicesIcon {
  padding: 50px 30px;
}
.list li i {
  color: #0880e8;
  font-size: 15px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
}

.list {
  margin: 10px 30px;
  padding: 0px 20px 0px 0px;
}

.list li {
  display: block;
  margin-bottom: 12px;
  font-size: 14px;
  color: #777;
  padding-left: 25px;
  position: relative;
}
</style>
